<template>
  <el-dialog
    width="530px"
    :title="title"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleColse"
  >
    <el-form ref="form" inline label-width="100px" :model="paramsForm" :rules="rules">
      <el-form-item label="菜单名称" prop="name">
        <el-input v-model.trim="paramsForm.name" placeholder="请输入菜单名称" style="width: 380px"></el-input>
      </el-form-item>
      <el-form-item label="唯一编码" prop="code">
        <el-input v-model.trim="paramsForm.code" placeholder="请输入唯一编码" style="width: 380px"></el-input>
      </el-form-item>
      <el-form-item label="菜单类型" prop="type">
        <el-radio-group v-model="paramsForm.type">
          <el-radio label="1">菜单</el-radio>
          <el-radio label="2">按钮</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="菜单所属" prop="belong">
        <el-radio-group v-model="paramsForm.belong">
          <el-radio label="2">管理员</el-radio>
          <el-radio label="1">其他</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="所属平台" prop="app">
        <el-radio-group v-model="paramsForm.app" @change="radioChange">
          <el-radio label="2">web</el-radio>
          <el-radio label="1">app</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="所属系统" prop="appType">
        <el-radio-group v-model="paramsForm.appType">
          <el-radio label="1">安全</el-radio>
          <el-radio label="2">环保</el-radio>
          <el-radio label="3">职业健康</el-radio>
          <el-radio label="4">协同办公</el-radio>
          <el-radio label="5">技术管理</el-radio>
          <el-radio label="6">质量管理</el-radio>
          <el-radio label="7">科研管理</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="上级菜单" prop="pidValue">
        <el-cascader
          v-model="paramsForm.pidValue"
          :options="orgTree"
          :props="defaultProps"
          clearable
          :show-all-levels="false"
          style="width: 380px"
        ></el-cascader>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleColse">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getItem } from '@/utils/storage.js';
import { getMenuTree, postMenuInfo } from '@/api/super-admin/menu-mgr.js';

export default {
  data() {
    return {
      visible: false,
      title: '新增菜单',
      confirmLoading: false,
      defaultProps: {
        checkStrictly: true,
        value: 'id',
        label: 'title'
      },
      paramsForm: {
        tenantId: '', //
        id: '',
        pidValue: [],
        appType: '',
        pid: '',
        name: '',
        code: '', // 唯一编码
        type: '1', // 1菜单 2按钮
        belong: '',
        router: '无效', // 固定字段
        openType: '0', // 固定字段
        visible: 'Y', // 固定字段
        weight: '1', // 固定字段
        app: '2'
      },
      rules: {
        name: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入唯一编码', trigger: 'blur' }],
        type: [{ required: true, message: '请选择菜单类型', trigger: 'change' }],
        belong: [{ required: true, message: '请选择菜单所属', trigger: 'change' }],
        pidValue: [{ required: true, message: '请选择上级菜单', trigger: 'change' }],
        appType: [{ required: true, message: '请选择所属系统', trigger: 'change' }],
        app: [{ required: true, message: '请选择所属平台', trigger: 'change' }]
      },
      orgTree: [
        {
          title: 'web',
          id: '0',
          children: []
        }
      ],
      appOrgTree: [],
      webOrgTree: []
    };
  },
  created() {
    const { tenantId } = getItem('tenantInfo');
    this.paramsForm.tenantId = tenantId;
  },
  methods: {
    getMenuTreeListData() {
      getMenuTree({ type: 2 }).then(res => {
        this.orgTree[0].children = res.data.webMenusTree;
        this.webOrgTree = res.data.webMenusTree;
      });
      getMenuTree({ type: 1 }).then(res => {
        this.appOrgTree = res.data.appMenusTree;
      });
    },
    open(row) {
      this.visible = true;
      this.getMenuTreeListData();
      if (row.id) {
        this.title = '编辑菜单';
        this.$nextTick(() => {
          if (row.app === 1) {
            setTimeout(() => {
              this.orgTree[0].children = this.appOrgTree;
            }, 500);
            this.orgTree[0].title = 'app';
          }
          // TODO: 设置 pid pidValue
          this.paramsForm.id = row.id;
          this.paramsForm.app = row.app.toString();
          this.paramsForm.name = row.title;
          this.paramsForm.code = row.code;
          this.paramsForm.type = row.type + '';
          this.paramsForm.appType = row.appType + '';
          this.paramsForm.belong = row.belong + '';
        });
      } else {
        this.title = '新增菜单';
      }
    },
    radioChange(data) {
      if (data === '2') {
        this.orgTree[0].children = this.webOrgTree;
        this.orgTree[0].title = 'web';
      } else {
        this.orgTree[0].title = 'app';
        this.orgTree[0].children = this.appOrgTree;
      }
    },
    handleColse() {
      this.visible = false;
      this.title = '新增菜单';
      this.$refs.form.resetFields();
      this.paramsForm.id = '';
      this.paramsForm.pid = '';
      this.paramsForm.pidValue = [];
      this.orgTree[0].title = 'web';
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        const { pidValue } = this.paramsForm;
        this.paramsForm.pid = pidValue[pidValue.length - 1];
        this.onPostMenuInfo(this.paramsForm);
      });
    },
    onPostMenuInfo(params) {
      this.confirmLoading = true;
      postMenuInfo(params)
        .then(res => {
          const { id } = params;
          this.$message.success(`${id ? '编辑' : '新增'}菜单成功！`);
          this.$emit('on-success');
          this.handleColse();
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    }
  }
};
</script>
