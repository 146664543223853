<!--
 * @Author: your name
 * @Date: 2021-11-08 11:07:23
 * @LastEditTime: 2021-12-07 12:14:00
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\super-admin\menu-mgr\index.vue
-->
<template>
  <div>
    <el-button type="primary" icon="el-icon-plus" style="margin-bottom: 16px" @click="handleAdd">新增</el-button>

    <el-table
      :data="menuTreeList"
      row-key="id"
      default-expand-all
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="title" label="菜单名称"></el-table-column>
      <el-table-column prop="code" label="唯一编码"></el-table-column>
      <el-table-column align="center" width="200" label="类型">
        <template slot-scope="{ row }">
          <el-tag v-if="row.type === 1">菜单</el-tag>
          <el-tag v-else-if="row.type === 2" type="success">按钮</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" width="200" label="操作">
        <template slot-scope="{ row }">
          <div>
            <span class="action-btn mr-24" @click="handleEdit(row)" v-if="row.id !== '0' && row.id !== '1'">编辑</span>
            <span class="action-btn danger-btn" @click="handleDelete(row)" v-if="row.id !== '0' && row.id !== '1'"
              >删除</span
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增/编辑弹窗 -->
    <MenuDialog ref="menuDialogRef" @on-success="getMenuTreeListData" />
  </div>
</template>

<script>
import MenuDialog from './menuDialog.vue';

import { getMenuTree, deleteMenu } from '@/api/super-admin/menu-mgr.js';

export default {
  components: { MenuDialog },
  data() {
    return {
      menuTreeList: [
        {
          title: 'web',
          id: 'web',
          children: []
        },
        {
          title: 'app',
          id: 'app',
          children: []
        }
      ]
    };
  },
  created() {
    this.getMenuTreeListData();
  },
  methods: {
    getMenuTreeListData() {
      getMenuTree({ type: 3 }).then(res => {
        this.menuTreeList[0].children = res.data.webMenusTree;
        this.menuTreeList[1].children = res.data.appMenusTree;
      });
    },
    handleAdd() {
      this.$refs.menuDialogRef.open();
    },
    handleEdit(row) {
      this.$refs.menuDialogRef.open(row);
    },
    handleDelete(row) {
      this.$confirm('是否确认删除该菜单，及其子菜单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return deleteMenu({ id: row.id });
        })
        .then(res => {
          this.$message.success('删除菜单成功！');
          this.getMenuTreeListData();
        });
    }
  }
};
</script>
